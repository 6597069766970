<template>
  <div>
    <div class="tabs tabs-rcs mb-5">
      <ul class="d-flex">
        <li
          class="tab-rcs"
          v-for="(tab, index) in tabs"
          v-bind:key="index"
          v-bind:class="{ 'is-active': tab.isActive }"
        >
          <a :href="tab.href" @click="selectTab(tab)">{{ tab.name }}</a>
        </li>
      </ul>
    </div>

    <div class="tabs-details rcs">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return { tabs: [] }
  },

  created() {
    this.tabs = this.$children
    this.$parent.$on('activeTab', this.activeTabInfo)
  },
  methods: {
    activeTabInfo(key) {
      this.selectTab({
        name: key.toString()
      })
    },
    selectTab(selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = tab.name == selectedTab.name
      })
    }
  }
}
</script>

<style lang="scss">
.tabs-rcs {
  overflow: auto;
}
.tab-rcs {
  padding: 10px;
  &.is-active {
    border-bottom: solid 1px orange;
  }
}

.tab-details.rcs {
  overflow: hidden;
}

.tabItemRCS {
  &.active {
    transform: translateX(0px);
    opacity: 1;
  }
  &.out {
    transform: translateX(-500px);
    opacity: 0;
  }
  opacity: 0;
  transform: translateX(500px);
  transition: 0.7s ease-in-out;
}

.tabs-details.rcs {
  overflow: hidden;
}

/*
 *  STYLE 1
 */

.tabs-rcs::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  background-color: #f5f5f5;
}

.tabs-rcs::-webkit-scrollbar {
  width: 1px;
  height: 10px;
  background-color: #f5f5f5;
}

.tabs-rcs::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
</style>
